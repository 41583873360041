// 最外层盒子样式
%out-box {
  // width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以100；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

// .el-carousel__indicators--horizontal {
//   bottom: -0.4rem;
// }
@mixin text-overflow($width: 100%) {
  width: $width;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.homepage-index-to-more {
  width: (241rem / 100);
  height: (60rem / 100);
  margin: 0 auto;
}

.homepage-index-bg {
  @extend %out-box;
  padding-bottom: (239rem / 100);
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");

  .homepage-index-news-bg {
    @extend %out-box;

    margin-bottom: (80rem / 100);
    &-swiper {
      height: 7rem;
      position: relative;
      .el-carousel {
        position: static;
      }
      .el-carousel__indicators--horizontal {
        position: absolute;
        bottom: -0.4rem;
        .el-carousel__indicator--horizontal {
          padding-left: 0.05rem;
          padding-right: 0.05rem;
        }
        .el-carousel__button {
          width: 0.54rem;
          height: 0.06rem;
          background-color: #cd9e56;
          transform: skewX(-40deg);
        }
      }
    }
    .homepage-index-news {
      @extend %inside-box;
      padding-top: (68rem / 100);
      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: (30rem / 100);

        &-left {
          display: flex;
          align-items: flex-end;

          &-icon {
            width: (14rem / 100);
            height: (30rem / 100);
          }

          &-title {
            width: (116rem / 100);
            height: (26rem / 100);
            margin: 0 (22rem / 100);
          }
        }

        &-right {
          display: flex;
          align-items: center;
          img {
            width: 0.2rem;
            height: 0.34rem;
          }
          .news-button {
            width: 1.16rem;
            height: 0.26rem;
            margin-right: 0.34rem;
            &:nth-child(2) {
              margin-right: 0.21rem;
            }
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }

      &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: (74rem / 100);
        height: (505rem / 100);
        background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage_news_content_bg.png")
          no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: (20rem / 100);

        &-left {
          // width: (610rem / 100);
          margin-right: (30rem / 100);
          // float: left;

          &-ul {
            width: 100%;

            &-li {
              width: 100%;
              height: (84rem / 100);
              display: flex;
              align-items: center;
              margin-bottom: (10rem / 100);

              a {
                display: flex;
                align-items: center;
              }

              &-left {
                width: (60rem / 100);
                margin-right: (10rem / 100);

                &-day {
                  font-size: (26rem / 100);
                  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: (26rem / 100);
                  margin-bottom: (4rem / 100);
                }

                &-date {
                  font-size: (14rem / 100);
                  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                  color: #ffffff;
                  line-height: (14rem / 100);
                  white-space: nowrap;
                }
              }

              &-right {
                width: (473rem / 100);
                margin-top: (8rem / 100);
                padding: (20rem / 100) (3rem / 100) (20rem / 100) (10rem / 100);

                &-title {
                  font-size: (18rem / 100);
                  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                  font-weight: 500;
                  color: #ffffff;
                  line-height: (18rem / 100);
                  @include text-overflow();
                  margin-bottom: (12rem / 100);
                }

                &-des {
                  font-size: (14rem / 100);
                  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                  color: rgba($color: #ffffff, $alpha: 0.8);
                  line-height: (24rem / 100);
                  @include text-overflow();
                }
              }

              &-right:hover {
                background: #7e0101;
              }
            }

            &-li:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        &-right {
          width: (600rem / 100);

          &-banner {
            width: 100%;
            height: (360rem / 100);
            overflow: hidden;
            margin-bottom: (11rem / 100);
          }

          &-bottom {
            width: 100%;
            position: relative;

            &-title {
              width: 100%;
              font-size: (18rem / 100);
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              color: #ffffff;
              line-height: (28rem / 100);
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin-bottom: (18rem / 100);
            }

            &-date {
              width: 100%;
              text-align: right;
              font-size: (16rem / 100);
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              color: rgba($color: #ffffff, $alpha: 0.8);
              line-height: (16rem / 100);
            }
            &-more {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: 0.29rem;
              span {
                font-size: 0.2rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 0.2rem;
              }
              img {
                width: 0.11rem;
                height: 0.2rem;
                margin-left: 0.12rem;
              }
            }
          }
        }
      }
    }
  }

  .homepage-index-activity-bg {
    @extend %out-box;
    margin-bottom: (37rem / 100);

    .homepage-index-activity {
      @extend %inside-box;

      .homepage-index-activity-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: (30rem / 100);

        &-left {
          display: flex;
          align-items: flex-end;

          &-icon {
            width: (14rem / 100);
            height: (30rem / 100);
          }

          &-title {
            margin: 0 (22rem / 100);
            display: flex;
            align-items: center;
            position: relative;

            &-img {
              width: (117rem / 100);
              height: (25rem / 100);
              display: block;
              position: relative;
              z-index: 1;
              cursor: pointer;
            }

            &-dot {
              width: (8rem / 100);
              height: (8rem / 100);
              background: #cd9e56;
              border-radius: 50%;
              margin: 0 (20rem / 100);
            }

            &-sub-img {
              width: (111rem / 100);
              height: (25rem / 100);
              display: block;
              z-index: 1;
              cursor: pointer;
            }

            .homepage-title-img-bg {
              width: (120rem / 100);
              height: (16rem / 100);
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 0;
            }
          }
        }

        &-right {
          width: (80rem / 100);
          height: (26rem / 100);
          line-height: (26rem / 100);
          font-size: (16rem / 100);
          text-indent: (18rem / 100);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage_more_bg.png")
            no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
        }
      }

      &-ul {
        display: flex;
        align-items: center;
      }

      &-li {
        width: (380rem / 100);
        height: (390rem / 100);
        margin-right: (30rem / 100);
        background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
          no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: (20rem / 100);
        position: relative;
        cursor: pointer;

        &-banner {
          width: 100%;
          height: (200rem / 100);
          overflow: hidden;
          margin-bottom: (14rem / 100);
        }

        &-bottom {
          width: 100%;
          padding: (6rem / 100) (10rem / 100) 0;

          &-title {
            height: (56rem / 100);
            font-size: (18rem / 100);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #273143;
            line-height: (28rem / 100);
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: (11rem / 100);
          }

          &-des {
            width: 100%;
            // height: (16rem / 100);
            margin-bottom: (10rem / 100);
            display: flex;
            align-items: center;

            .homepage-index-activity-li-bottom-icon {
              width: (24rem / 100);
              height: (24rem / 100);
              // float: left;
              margin-right: (10rem / 100);
            }

            .homepage-index-activity-li-bottom-icon-title {
              // float: left;
              @include text-overflow(
                $width: (
                  340rem / 100,
                )
              );
              font-size: (16rem / 100);
              font-family: PingFangSC-Regular, PingFang SC;
              color: #273143;
              line-height: (16rem / 100);
            }
          }
        }
      }

      .homepage-index-activity-li-selected {
        width: 100%;
        height: (37rem / 100);
        position: absolute;
        left: 0;
        bottom: 0;
        display: none;
      }

      &-li:last-of-type {
        margin-right: 0;
      }

      &-li:hover {
        .homepage-index-activity-li-selected {
          display: block;
        }
      }

      .homepage-index-artistic {
        height: (310rem / 100) !important;
        background: rgba($color: #f0e1c1, $alpha: 0.4);

        &-banner {
          height: (180rem / 100);
        }
      }
    }
  }

  .homepage-index-culture-bg {
    @extend %out-box;
    height: (520rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage_live_culture_bg.png")
      no-repeat;
    background-size: 100% 100%;
    margin-bottom: (30rem / 100);
    padding-top: (35rem / 100);

    .homepage-index-culture {
      @extend %inside-box;

      .homepage-index-culture-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: (32rem / 100);

        &-left {
          display: flex;
          align-items: flex-end;

          &-icon {
            width: (14rem / 100);
            height: (30rem / 100);
          }

          &-title {
            margin: 0 (22rem / 100);
            display: flex;
            align-items: center;
            position: relative;

            &-img {
              width: (117rem / 100);
              height: (25rem / 100);
              display: block;
              position: relative;
              z-index: 1;
              cursor: pointer;
            }

            &-dot {
              width: (8rem / 100);
              height: (8rem / 100);
              background: #cd9e56;
              border-radius: 50%;
              margin: 0 (20rem / 100);
            }

            &-sub-img {
              width: (111rem / 100);
              height: (25rem / 100);
              display: block;
              z-index: 1;
              cursor: pointer;
            }

            .homepage-title-img-bg {
              width: (120rem / 100);
              height: (16rem / 100);
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 0;
            }
          }
        }

        &-right {
          width: (80rem / 100);
          height: (26rem / 100);
          line-height: (26rem / 100);
          font-size: (16rem / 100);
          text-indent: (18rem / 100);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage_more_bg.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      &-ul {
        display: flex;
        align-items: center;
      }

      &-li {
        width: (380rem / 100);
        height: (390rem / 100);
        margin-right: (30rem / 100);
        background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
          no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: (20rem / 100);
        position: relative;
        cursor: pointer;
        &-banner {
          width: 100%;
          height: (200rem / 100);
          overflow: hidden;
          margin-bottom: (14rem / 100);
        }

        &-bottom {
          padding: (14rem / 100) 0;

          &-title {
            height: (56rem / 100);
            font-size: (18rem / 100);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #273143;
            line-height: (28rem / 100);
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: (8rem / 100);
          }
          &-intro {
            width: 100%;
            font-size: 0.14rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #273143;
            line-height: 0.22rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          &-des {
            width: 100%;
            // height: (16rem / 100);
            margin-bottom: (10rem / 100);
            display: flex;
            align-items: center;

            .homepage-index-culture-li-bottom-icon {
              width: (24rem / 100);
              height: (24rem / 100);
              // float: left;
              margin-right: (10rem / 100);
            }

            .homepage-index-culture-li-bottom-icon-title {
              // float: left;
              @include text-overflow(
                $width: (
                  340rem / 100,
                )
              );
              font-size: (16rem / 100);
              font-family: PingFangSC-Regular, PingFang SC;
              color: #273143;
              line-height: (16rem / 100);
            }
          }
        }
      }

      .homepage-index-culture-li-selected {
        width: 100%;
        height: (37rem / 100);
        position: absolute;
        left: 0;
        bottom: 0;
        display: none;
      }

      &-li:last-of-type {
        margin-right: 0;
      }

      &-li:hover {
        .homepage-index-culture-li-selected {
          display: block;
        }
      }

      .homepage-index-artistic {
        height: (390rem / 100) !important;
        background: rgba($color: #f3ede0, $alpha: 1);
        border: 0.04rem solid transparent;
        &-banner {
          height: (200rem / 100);
        }
        &:hover {
          border: 0.04rem solid #cd9e56;
        }
      }
    }
  }
  .homepage-index-intangible-bg {
    @extend %out-box;
    margin-bottom: (37rem / 100);

    .homepage-index-intangible {
      @extend %inside-box;

      .homepage-index-intangible-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: (30rem / 100);

        &-left {
          display: flex;
          align-items: flex-end;

          &-icon {
            width: (14rem / 100);
            height: (30rem / 100);
          }

          &-title {
            margin: 0 (22rem / 100);
            display: flex;
            align-items: center;
            position: relative;

            &-img {
              width: (117rem / 100);
              height: (25rem / 100);
              display: block;
              position: relative;
              z-index: 1;
              cursor: pointer;
            }

            &-dot {
              width: (8rem / 100);
              height: (8rem / 100);
              background: #cd9e56;
              border-radius: 50%;
              margin: 0 (20rem / 100);
            }

            &-sub-img {
              width: (111rem / 100);
              height: (25rem / 100);
              display: block;
              z-index: 1;
              cursor: pointer;
            }

            .homepage-title-img-bg {
              width: (120rem / 100);
              height: (16rem / 100);
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 0;
            }
          }
        }

        &-right {
          width: (80rem / 100);
          height: (26rem / 100);
          line-height: (26rem / 100);
          font-size: (16rem / 100);
          text-indent: (18rem / 100);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage_more_bg.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }

      &-ul {
        display: flex;
        align-items: center;
      }

      &-li {
        width: (380rem / 100);
        height: (310rem / 100);
        margin-right: (30rem / 100);
        background-color: #f3ede0;
        box-sizing: border-box;
        padding: (20rem / 100);
        position: relative;
        cursor: pointer;
        &-banner {
          width: 100%;
          height: (200rem / 100);
          overflow: hidden;
          margin-bottom: (14rem / 100);
        }

        &-bottom {
          width: 100%;
          padding: (14rem / 100) 0;

          &-title {
            height: (56rem / 100);
            font-size: (18rem / 100);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #273143;
            line-height: (28rem / 100);
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: (8rem / 100);
          }
          &-intro {
            width: 100%;
            font-size: 0.14rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #273143;
            line-height: 0.22rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }

          &-des {
            width: 100%;
            // height: (16rem / 100);
            margin-bottom: (10rem / 100);
            display: flex;
            align-items: center;

            .homepage-index-intangible-li-bottom-icon {
              width: (24rem / 100);
              height: (24rem / 100);
              // float: left;
              margin-right: (10rem / 100);
            }

            .homepage-index-intangible-li-bottom-icon-title {
              // float: left;
              @include text-overflow(
                $width: (
                  340rem / 100,
                )
              );
              font-size: (16rem / 100);
              font-family: PingFangSC-Regular, PingFang SC;
              color: #273143;
              line-height: (16rem / 100);
            }
          }
        }
      }

      .homepage-index-intangible-li-selected {
        width: 100%;
        height: (37rem / 100);
        position: absolute;
        left: 0;
        bottom: 0;
        display: none;
      }

      &-li:last-of-type {
        margin-right: 0;
      }

      &-li:hover {
        .homepage-index-intangible-li-selected {
          display: block;
        }
      }

      .homepage-index-artistic {
        height: (390rem / 100) !important;
        background: rgba($color: #f3ede0, $alpha: 1);
        border: 0.04rem solid transparent;
        &:hover {
          border-color: #cd9e56;
        }
        &-banner {
          height: (200rem / 100);
        }
      }
    }
  }
  .homepage-index-reserve-bg {
    @extend %out-box;
    margin-bottom: (37rem / 100);

    &-bgPic {
      width: 9.5rem;
      height: 3.7rem;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .homepage-index-reserve {
      @extend %inside-box;

      .homepage-index-reserve-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: (30rem / 100);

        &-left {
          display: flex;
          align-items: flex-end;

          &-icon {
            width: (14rem / 100);
            height: (30rem / 100);
          }

          &-title {
            margin: 0 (22rem / 100);
            display: flex;
            align-items: center;
            position: relative;

            &-img {
              width: (117rem / 100);
              height: (25rem / 100);
              display: block;
              position: relative;
              z-index: 1;
              cursor: pointer;
            }

            &-dot {
              width: (8rem / 100);
              height: (8rem / 100);
              background: #cd9e56;
              border-radius: 50%;
              margin: 0 (20rem / 100);
            }

            &-sub-img {
              width: (111rem / 100);
              height: (25rem / 100);
              display: block;
              z-index: 1;
              cursor: pointer;
            }

            .homepage-title-img-bg {
              width: (120rem / 100);
              height: (16rem / 100);
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 0;
            }
          }
        }

        &-right {
          width: (80rem / 100);
          height: (26rem / 100);
          line-height: (26rem / 100);
          font-size: (16rem / 100);
          text-indent: (18rem / 100);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage_more_bg.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .homepage-index-reserve-content {
        @extend %inside-box;
        min-height: 5.16rem;
        position: relative;
        z-index: 1;
        &-left {
          float: left;
          width: 50%;
          height: 3.6rem;
          background-color: #eee;
        }
        &-right {
          float: left;
          width: 50%;
          &-title {
            width: 100%;
            height: 0.7rem;
            background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepga-reserve-title-bg.png")
              no-repeat;
            background-size: 100% 100%;
            font-size: 0.24rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #761f1e;
            line-height: 0.7rem;
            padding-left: 0.2rem;
            margin-bottom: 0.42rem;
          }
          &-info {
            padding-left: 0.2rem;
            li {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              font-size: 0.16rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #273143;
              line-height: 0.22rem;
              margin-top: 0.21rem;
              margin-bottom: 0.17rem;
              img {
                width: 0.24rem;
                height: 0.24rem;
                margin-right: 0.12rem;
              }
              span {
                font-weight: 600;
                white-space: nowrap;
              }
              .timeBox {
                width: 4.8rem;
                p {
                  width: 4.4rem;
                }
              }
            }
          }
          &-reserveBtn {
            width: 1.4rem;
            height: 0.44rem;
            line-height: 0.44rem;
            text-align: center;
            background-color: #761f1e;
            font-size: 0.16rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            border-radius: 0.25rem;
            cursor: pointer;
            margin-left: 0.2rem;
            // margin-top: 0.37rem;
            margin-top: 0.42rem;
          }
          &-line {
            width: 6rem;
            height: 1.1rem;
            // margin-top: 0.3rem;
            margin-top: 0.6rem;
            margin-left: 0.2rem;
            overflow: hidden;
            &-list {
              position: relative;
              align-items: center;
              height: 100%;
              li {
                float: left;
                margin-right: 0.26rem;
                cursor: pointer;
                .picbox {
                  width: 0.6rem;
                  height: 0.36rem;
                  margin-top: 0.21rem;
                  margin-bottom: 0.28rem;
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    width: 0.1rem;
                    height: 0.1rem;
                    background-color: #cd9e56;
                    border-radius: 50%;
                    left: 50%;
                    bottom: -0.22rem;
                    z-index: 3;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .activePic {
                  width: 1.2rem;
                  height: 0.72rem;
                  border: 0.04rem solid #cd9e56;
                  margin-bottom: 0.13rem;

                  margin-top: 0;
                  &::after {
                    display: none;
                  }
                }
                p {
                  width: 0.96rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: 0.16rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #761f1e;
                  line-height: 0.16rem;
                }
              }
              .crossline {
                width: 100%;
                height: 0.03rem;
                background-color: #d8d8d8;
                position: absolute;
                top: 0.72rem;
              }
            }
          }
        }
      }
    }
  }
}

.homepage-fix-enter {
  position: fixed;
  left: (37rem / 100);
  top: (318rem / 100);
  width: (82rem / 100);
  padding: (35rem / 100) (10rem / 100) (30rem / 100);
  height: (378rem / 100);
  background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-leftfix-bg.png")
    no-repeat;
  background-size: 100% 100%;
  z-index: 100000;

  .homepage-fix-enter-box {
    width: 100%;

    &-ul {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-li {
        width: 100%;
        margin-bottom: (30rem / 100);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        &-div {
          width: (54rem / 100);
          height: (54rem / 100);
          position: relative;
          margin-bottom: 0.1rem;
          &-positions {
            padding: (20rem / 100) (18rem / 100) (25rem / 100);
            position: absolute;
            right: -(330rem / 100);
            top: (-10rem / 100);
            background: #ffffff;
            border-radius: (20rem / 100);
            text-align: center;
            font-size: (14rem / 100);
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #fff;
            line-height: (21rem / 100);
            display: none;
            &-title {
              color: #333;
            }
          }

          &-positions1 {
            width: (300rem / 100);
            padding: (20rem / 100) 0 (25rem / 100);
            position: absolute;
            right: -(330rem / 100);
            top: 0;
            background: #ffffff;
            border-radius: (20rem / 100);
            text-align: center;
            font-size: (14rem / 100);
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #fff;
            line-height: (21rem / 100);
            display: none;
          }
        }

        &-return {
          width: (54rem / 100);
          height: (54rem / 100);
          margin: 0 auto (12rem / 100);
        }

        &-title {
          text-align: center;
          font-size: (14rem / 100);
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #fff;
          line-height: (21rem / 100);
        }
      }

      .homepage-fix-enter-box-ul-li1:hover {
        .homepage-fix-enter-box-ul-li-div-positions {
          display: block;
        }
      }

      .homepage-fix-enter-box-ul-li2:hover {
        .homepage-fix-enter-box-ul-li-div-positions1 {
          display: block;
        }
      }
    }
  }
}
// 首页直播列表
.live-ul1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (424rem / 100);
    box-sizing: border-box;
    background: #f3ede0;
    margin-right: (30rem / 100);
    border: 4px solid #f3ede0;
    cursor: pointer;

    &-banner {
      width: (340rem / 100);
      height: (200rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (5rem / 100);
      }

      &-status {
        width: (100rem / 100);
        height: (26rem / 100);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        text-align: center;
        line-height: (26rem / 100);
        margin-bottom: (10rem / 100);
      }

      .live-status-bg1 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_starting.png");
        color: #ffffff;
      }

      .live-status-bg2 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_not_start.png");
        color: #761f1e;
      }

      &-sponsor {
        margin-bottom: (8rem / 100);
      }

      &-sponsor {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (16rem / 100);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-summary {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (24rem / 100);
        width: 3.4rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}


.listState{
  position: absolute;
  left: 0rem;
  top: 0rem;
  padding: 0.04rem 0.08rem 0.04rem 0.08rem;
  color: white;
}
.current1{
  background: rgb(170,170,170);
}
.current2{
  background: rgb(245,154,35);
}
.current4{
  background: rgb(112,182,3);
}
.listStateFont{
  font-size: 0.15rem;
}
.resources-ul-li{
  overflow: hidden;
}